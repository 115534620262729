import ListSelector from "~/components/ListSelector.vue";
import ProductOptionSelector from "../components/ProductOptionSelector.vue";
import ProductPromoSelector from "../components/ProductPromoSelector.vue";

import login from "./login";

import { mapActions } from "vuex";
import CartService from "~/service/cartService";
import CatalogService from "~/service/catalogService";
import ListService from "~/service/listService";

import get from "lodash/get";
import set from "lodash/set";
// import toNumber from "lodash/toNumber";
import isNil from "lodash/isNil";

export default {
  mixins: [login],

  data() {
    return {
      internalQuantity: global.config.updateCart == true ? 0 : -1,
      heartKey: 0,
      showQntyInput: false,
      qntyInput: 0,
      higherInput: 0,
      lowerInput: 0,
      currentOption: null
      //selectedOptions: {}
    };
  },
  computed: {
    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(p =>
          global.config.filterProductClasses.includes(p.productClassId)
        );
      } else {
        return this.product.productClasses;
      }
    },
    productVendor() {
      return this.product.vendor;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.isNew) {
        productClasses.push("new-product");
      }
      return productClasses;
    },
    quantity: {
      get() {
        if (this.item) {
          let item = this.item;
          if (isNil(item)) return 0;
          if (item.product.productInfos.TIPOLOGIA == "Sfuso") {
            //Handling floating point decimals issues
            var val = (item.weight * 1000).toFixed(0);
            val = val * CatalogService.getMultiplier(item.product);
            val = val / 1000;
            return val;
          } else {
            return item.quantity;
          }
        } else {
          if (this.internalQuantity == -1) {
            this.internalQuantity = CartService.plus(this.product);
          }
          return this.internalQuantity;
        }
      },
      set(val) {
        this.internalQuantity = val;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT)
          );
          var singleUnitUm =
            this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM || "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit;
          if (this.item?.cartItemInfo?.customWeight) {
            singleUnit =
              quantity * parseFloat(this.item.cartItemInfo.customWeight);
          } else {
            singleUnit =
              quantity * parseFloat(this.product.productInfos.WEIGHT_SELLING);
          }

          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.product.productInfos) {
        if (this.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.product.productInfos.UNIT_SELLING || "pz";
        }
      }
    },
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso") {
        let qty = this.item ? this.item.quantity : this.quantity;
        const logisticPackagingItems =
          this.product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
          this.product.logisticPackagingItems;
        if (
          this.product.logisticPalletItems > 0 &&
          qty % this.product.logisticPalletItems == 0
        ) {
          return global.EventBus.$tc(
            "product.qty.pallet",
            qty / this.product.logisticPalletItems
          );
        } else if (
          logisticPackagingItems > 0 &&
          qty % logisticPackagingItems == 0
        ) {
          let unit = qty / logisticPackagingItems;
          return global.EventBus.$tc("product.qty.packaging", unit);
        }
      }
    },
    highlight() {
      if (this.heartKey) return this.product.highlight || false;
      return this.product.highlight;
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct",
      setItem: "cart/setItem",
      removeItem: "cart/removeItem",
      sendGiftCode: "cart/sendGiftCode"
    }),
    //not used anymore, checked server side!!!
    // getMaxAcq(product) {
    //   //calcolo se esiste un limite si acquisto per il prodotto
    //   //prendo la prop maxAcq e se non c'è la available
    //   let maxAcq = get(product, "maxAcq", product.available);

    //   //se il prodotto è un multipack, la limitazione va divisa per multipack
    //   let multiPack = get(product, "productInfos.MULTI_PACK", 1);
    //   maxAcq = maxAcq / multiPack;
    //   return maxAcq;
    // },
    async checkSelectOptionAndAdd(quantity, params, searchParams) {
      let leftOptions = this.getOptionsToSelect();
      let shouldAdd = await (leftOptions.length == 0 ||
        this.openOptionsModal(leftOptions));
      if (shouldAdd.customWeight) {
        this.product.productInfos["WEIGHT_SELLING"] = shouldAdd.customWeight;
        this.product.productInfos["WEIGHT_SELLING_SINGLE_UNIT"] =
          shouldAdd.customWeight;
      }
      //che selectable promo
      let useWpId = null;
      if (this.product?.warehousePromo?.selectable == true) {
        useWpId = await this.$dialog.show(ProductPromoSelector, {
          waitForResult: true,
          fullscreen: this.$vuetify.breakpoint.smAndDown,
          warehousePromo: this.product.warehousePromo
        });
      }
      if (!params) {
        params = {};
      }
      if (shouldAdd) {
        //if i choose customWeight options, product.weight must be updated
        if (typeof shouldAdd === "object") {
          params.infos = {
            ...params.infos,
            ...shouldAdd
          };
          for (var key of Object.keys(shouldAdd)) {
            for (let i = 0; i < this.product.selectOptions.length; i++) {
              let option = this.product.selectOptions[i];
              if (option.parameterName === key) {
                this.product.selectOptions[i].selected = {
                  key: key,
                  value: shouldAdd[key]
                };
              }
            }
          }
        }
        if (useWpId) {
          params.useWpId = useWpId;
        } else if (useWpId === false) {
          if (params.infos) {
            params.infos.use_wpid = -1;
          } else {
            params.infos = { use_wpid: -1 };
          }
        }
        if (this.giftCode) {
          await this.sendGiftCode(this.giftCode);
        }
        this.addProduct({
          product: this.product,
          quantity: quantity,
          params: params,
          searchParams: searchParams
        });
      }
    },
    async plus(updateCartParam, params, searchParams) {
      let updateCart =
        typeof updateCartParam == "boolean"
          ? updateCartParam
          : global.config.updateCart;

      if (global.config.anonymousCart || (await this.needLogin("addtocart"))) {
        if (global.config.anonymousCart || (await this.needAddress())) {
          if (global.config.anonymousCart || (await this.needTimeslot())) {
            let ignoreMultiplier =
              get(params, "infos.is_package", "undefined") === "false";
            let newQuantity = CartService.plus(
              this.product,
              this.quantity,
              ignoreMultiplier
            );
            // let maxAcq = this.getMaxAcq(this.product);
            // if (newQuantity <= toNumber(maxAcq)) {
            if (updateCart) {
              if (this.quantity == 0) {
                //check if there are options to choose
                this.checkSelectOptionAndAdd(newQuantity, params, searchParams);
              } else {
                if (!ignoreMultiplier) {
                  //nel caso standard, importao la quantità dellitem sottostante
                  this.setItem({
                    item: this.item,
                    quantity: newQuantity,
                    searchParams: searchParams
                  });
                } else {
                  //nel caso stiamo lavorando sui joltiplicatori:
                  //se ho raggiunto la quantity del multiplier rimuovo e aggiungo con package
                  let qtyMult = parseFloat(this.product.productInfos.QTY_MULT);
                  if (newQuantity >= qtyMult) {
                    this.removeItem(this.item);
                    this.addProduct({
                      product: this.product,
                      quantity: newQuantity,
                      params: set(params, "infos.is_package", "true"),
                      searchParams: searchParams
                    });
                  } else {
                    this.setItem({
                      item: this.item,
                      quantity: newQuantity,
                      searchParams: searchParams
                    });
                  }
                }
              }
            } else {
              this.quantity = newQuantity;
            }
            // } else {
            //   global.EventBus.$emit("error", {
            //     message: global.EventBus.$t("errors.maxAcq")
            //   });
            // }
          }
        }
      }
    },
    minus(updateCartParam, params, searchParams) {
      let updateCart =
        typeof updateCartParam == "boolean"
          ? updateCartParam
          : global.config.updateCart;
      let ignoreMultiplier =
        get(params, "infos.is_package", "undefined") === "false";
      let newQuantity = 0;
      newQuantity = CartService.minus(
        this.product,
        this.quantity,
        ignoreMultiplier
      );
      let minAcq = this.product.minAcq || 0;
      if (newQuantity == 0) {
        //remove product
        if (updateCart) {
          this.remove();
        }
      } else if (newQuantity >= minAcq) {
        if (updateCart) {
          //set item quanntity test
          this.setItem({
            item: this.item,
            quantity: newQuantity,
            searchParams: searchParams
          });
        } else {
          this.quantity = newQuantity;
        }
      } else {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t("errors.minAcq")
        });
      }
    },
    async openSelectablePromoModal() {
      return await this.$dialog.show(ProductPromoSelector, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        warehousePromo: this.product.warehousePromo
      });
      // const result = await this.dialogInstance.wait();
      // if (result) {
      //   this.selectedOptions["useWpId"] = result;
      // }
      // return result;
    },
    async openOptionsModal(
      optionsToSelect,
      buttonLabel = `${this.$t("products.addToCart")}`
    ) {
      var config = {
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        optionsToSelect: optionsToSelect,
        buttonLabel: buttonLabel
      };
      if (global.config.optionsSelectorModal) {
        config = Object.assign(config, global.config.optionsSelectorModal);
      }
      this.dialogInstance = await this.$dialog.show(
        ProductOptionSelector,
        config
      );
      const result = await this.dialogInstance.wait();
      this.handleSelectedOptions(result);
      return result;
    },
    getOptionsToSelect() {
      let unselected = [];
      if (this.product.selectOptions) {
        this.product.selectOptions.forEach(option => {
          let selected = this.selectedOptions[option.parameterName];
          if (!selected) {
            unselected.push(option);
          }
        });
      }
      return unselected;
    },
    async addToCart(quantity, params, searchParams) {
      if (global.config.anonymousCart || (await this.needLogin("addtocart"))) {
        if (global.config.anonymousCart || (await this.needAddress())) {
          if (global.config.anonymousCart || (await this.needTimeslot())) {
            if (this.giftCode) {
              await this.sendGiftCode(this.giftCode);
            }
            //check if there are options to choose
            this.checkSelectOptionAndAdd(quantity, params, searchParams);
          }
        }
      }
    },
    async remove() {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.remove", {
          name: this.product.name,
          quantity: this.quantity,
          unit: this.unit
        })
      });
      if (res) this.removeItem(this.item);
    },
    async addToFavorites() {
      if (await this.needLogin("addtofav")) {
        let res = await this.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: this.$vuetify.breakpoint.smAndDown,
          width: global.config.addToFavoritesWidth || 300,
          product: this.product,
          quantity: this.quantity
        });
        let newQuantity = this.quantity
          ? this.quantity
          : CartService.plus(this.product, this.quantity);
        if (res) {
          ListService.addProductToList(this.product, res.listId, newQuantity);
          this.product.highlight = true;
          this.heartKey += 1;
        }
      }
    },
    approxQtyMultProduct(isPackage = false) {
      if (
        (this.product.productInfos.QTY_LOCKED == "true" ||
          isPackage === "true") &&
        this.qntyInput > 0
      ) {
        var newApproxValue = Math.round(
          this.qntyInput / this.product.productInfos.QTY_MULT
        );
        this.qntyInput =
          Math.max(newApproxValue, 1) * this.product.productInfos.QTY_MULT;
      }
      if (
        this.product.productInfos.MAXACQ &&
        this.qntyInput > this.product.productInfos.MAXACQ
      )
        this.qntyInput = this.product.productInfos.MAXACQ;
    },
    async toggleShowInput(save, params, isUpdateCart) {
      let _this = this;
      if (
        _this.product.productInfos.TIPOLOGIA == "Pezzo" ||
        _this.product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        if (_this.showQntyInput) {
          // chiudo input
          _this.showQntyInput = false;
          if (save) {
            // confermo salvataggio qntyInput
            _this.qntyInput = parseInt(_this.qntyInput);
            let isPackage = get(params, "infos.is_package", false);
            _this.approxQtyMultProduct(
              isPackage,
              isUpdateCart || global.config.updateCart
            );
            _this.internalQuantity = _this.qntyInput;
            if (
              _this.item &&
              _this.item.quantity != _this.internalQuantity &&
              _this.internalQuantity > 0
            ) {
              // se ho già l'item a carrello lo setto con la nuova qty (quindi anche per minicart e checkout per siti senza carrello modificativo)
              _this.setItem({
                item: _this.item,
                quantity: _this.internalQuantity
              });
            } else if (global.config.updateCart || isUpdateCart) {
              // solo carrello modificativo
              if (_this.item && _this.internalQuantity == 0) {
                // se esiste item e qty scelta è 0, allora cancello item dal carrello
                _this.removeItem(_this.item);
              } else if (!_this.item && _this.internalQuantity > 0) {
                // se non esiste item e qty > 0 -> add item se mai aggiunto al carrello
                _this.addProduct({
                  product: _this.product,
                  quantity: _this.internalQuantity,
                  params: params
                });
              }
            }
          }
        } else {
          // open and init manual input
          if (global.config.updateCart) {
            // se carrello modificativo
            _this.qntyInput = _this.item ? _this.item.quantity : 0;
          } else {
            if (_this.internalQuantity >= 0) {
              // product card and detail
              _this.qntyInput = _this.internalQuantity;
            } else {
              // checkout
              _this.qntyInput = _this.item.quantity;
            }
          }
          _this.showQntyInput = true;
          _this.$nextTick(() => {
            _this.$refs.manualInput.focus();
          });
        }
      }
    },
    downloadProductDetailDocument(type) {
      console.log("DOWNLOAD DOCUMENT - ", type);
    },
    modulus(value) {
      var lowerValue =
        Math.round(value / this.product.productInfos.QTY_MULT) *
        this.product.productInfos.QTY_MULT;
      var lowerResult = lowerValue;
      if (lowerValue > value) {
        lowerResult =
          (Math.round(value / this.product.productInfos.QTY_MULT) - 1) *
          this.product.productInfos.QTY_MULT;
      }
      var higherVResult =
        (Math.round(value / this.product.productInfos.QTY_MULT) + 1) *
        parseInt(this.product.productInfos.QTY_MULT);
      this.lowerInput =
        parseInt(value) <= parseInt(this.product.productInfos.QTY_MULT)
          ? this.product.productInfos.QTY_MULT
          : lowerResult;
      this.higherInput =
        lowerValue > value
          ? Math.round(value / this.product.productInfos.QTY_MULT) *
            parseInt(this.product.productInfos.QTY_MULT)
          : higherVResult;

      return (
        parseInt(value) % parseInt(this.product.productInfos.QTY_MULT) != 0
      );
    },
    setCurrentlySelectedOption(value) {
      if (typeof value === "object" && value.name && value.parameterName) {
        this.currentOption = value;
      } else {
        this.currentOption = this.product.selectOptions.find(o => {
          return o.parameterName === value;
        });
      }
    },
    handleSelectedOptions(result) {
      for (var i = 0; i < Object.keys(result).length; i++) {
        let key = Object.keys(result)[i];
        this.$set(this.selectedOptions, key, Object.values(result)[i]);
        this.setCurrentlySelectedOption(key);
      }
    },
    optionChanged(option) {
      this.setCurrentlySelectedOption(option);
    }
  }
};
