<template>
  <div>
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerGrid.TITLE"
      tag="h2"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerGrid.DESCRIPTION"
      tag="div"
    />

    <v-row class="banner-grid-row">
      <v-col
        v-for="(proposal, index) in config.proposals"
        :key="proposal.id"
        class="banner-grid-col"
        :cols="getColData(index, 'xs')"
        :sm="getColData(index, 'sm')"
        :md="getColData(index, 'md')"
        :lg="getColData(index, 'lg')"
        :xl="getColData(index, 'xl')"
      >
        <component
          :is="getProposalModelName(proposal)"
          :proposal="proposal"
          class="h-100"
        />
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.banner-grid-container {
  .swiper-slide {
    height: auto;
    .proposal-card.v-card {
      height: 100%;
    }
  }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import ProposalCardHorizontal from "./elements/ProposalCardHorizontal.vue";
import ProposalCard from "./elements/ProposalCard.vue";
import ProposalCardOverlay from "./elements/ProposalCardOverlay.vue";
import ProposalButton from "./elements/ProposalButton.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import get from "lodash/get";

export default {
  name: "BannerGrid",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    ProposalCardHorizontal,
    ProposalCard,
    ProposalCardOverlay,
    ProposalButton
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {};
  },
  computed: {
    rows() {
      return get(
        this.config,
        "metaData.categoryblocktype_BannerGrid.JSON_CONFIG.rows",
        [{ xs: "12", sm: "6", md: "4", lg: "4", xl: "4" }]
      );
    }
  },
  methods: {
    getColData(index, fieldPath) {
      if (index < this.rows.length) {
        return get(this.rows[index], fieldPath, 12);
      }
      return get(this.rows[0], fieldPath, 12);
    }
  }
};
</script>
