<template>
  <v-container class="address-selector fill-height">
    <v-layout fill-height column>
      <h2
        class="secondary--text text-center font-weight-bold mt-3 mb-4 mb-sm-0"
        v-if="category && category.metaData && category.metaData.category_info"
      >
        {{ category.metaData.category_info.TITLE }}
      </h2>
      <p
        class="selector-subitle"
        v-html="category.metaData.category_info.DESCRIPTION"
        v-if="
          category &&
            category.metaData &&
            category.metaData.category_info &&
            category.metaData.category_info.DESCRIPTION
        "
      ></p>
      <v-divider></v-divider>

      <v-row
        v-if="deliveryServices.length"
        no-gutters
        justify="space-between"
        align="center"
        class="my-3 tabs"
      >
        <v-col
          :cols="deliveryServiceButtonsLength"
          v-for="(service, idx) in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service, idx)"
        >
          <div
            class="tab d-flex flex-column align-center justify-center flex-grow-1 pa-4 mx-1 rounded-sm"
            :class="
              service.deliveryServiceId === selectedDeliveryServiceId
                ? `${service.cssClass} selected`
                : `${service.cssClass}`
            "
          >
            <img
              :src="$t(`navbar.service.${service.deliveryServiceId}.icon`)"
              :alt="'Immagine ' + service.name"
              contain
              class="mr-1"
              :height="$vuetify.breakpoint.xs ? 60 : 80"
            />
          </div>
        </v-col>
      </v-row>
      <v-card :loading="loading" flat>
        <div class="my-3 text-center text-sm-left">
          <div class="primary--text text-body-1 font-weight-bold mb-2">
            {{
              $t(
                `navbar.service.${deliveryService.deliveryServiceId}.deliveryText`
              )
            }}
          </div>
          <div class="secondary--text text-body-2">
            {{
              $t(
                `navbar.service.${deliveryService.deliveryServiceId}.deliverySub`
              )
            }}
          </div>
        </div>

        <v-list dense class="address-list pt-0">
          <v-list-item-group v-model="shippingAddress" color="primary">
            <v-list-item
              two-line
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-body-2 line-height-13"
                >
                  {{ address.addressName }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-body-2 font-weight-bold  line-height-13"
                >
                  {{
                    $t(
                      "navbar.address." + address.addressTypeId + ".list",
                      address
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row align-center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      large
                      v-bind="attrs"
                      v-on="on"
                      v-if="address.selected"
                      @click.stop
                      >$complete</v-icon
                    >
                  </template>
                  <span>{{
                    address.addressType == "home"
                      ? "Indirizzo preferito"
                      : "Negozio preferito"
                  }}</span>
                </v-tooltip>

                <v-icon
                  @click.stop="edit(address)"
                  v-if="address.addressType == 'home'"
                  color="primary"
                  class="ml-3"
                  >$edit</v-icon
                >

                <v-icon
                  @click.stop="remove(address)"
                  v-if="address.addressType == 'home'"
                  color="primary"
                  class="ml-4"
                  >$delete</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="deliveryService.deliveryServiceId === 2"
          color="primary"
          large
          depressed
          :to="{ name: 'EditAddress' }"
          @click="$emit('submit', false)"
        >
          AGGIUNGI INDIRIZZO
        </v-btn>
      </v-card-actions>
    </v-layout>
  </v-container>
</template>
<style scoped lang="scss">
.address-selector {
  h2 {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      line-height: 2;
      font-size: 16px;
    }
  }
  .tab {
    border: 2px solid $border-color;
    &.selected {
      color: $white !important;
      border-color: $primary;
      .v-icon,
      span {
        color: $white !important;
      }
    }
  }
  .address-list {
    overflow-y: auto;
    min-height: 200px;
    max-height: calc(100vh - 400px);
    //height: auto;
    // height: 400px;
    // border: 1px solid $border-color;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-height: 200px;
      max-height: calc(70vh - 230px);
      height: auto;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
//import login from "~/mixins/login";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { mapState } from "vuex";

export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      baseAddresses: [],
      addresses: [],
      provinces: [],
      selectedProvince: null,
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 2,
      searchText: null,
      category: {},
      showAddAddress: false
    };
  },
  computed: {
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      let _this = this;
      _this.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      _this.$emit("submit", true);
    },
    filterAddressesByProvince(province) {
      if (!province) {
        this.addresses = this.baseAddresses;
      } else {
        this.addresses = this.baseAddresses.filter(a => {
          return a.province === province;
        });
      }
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService) {
      let _this = this;
      _this.loading = true;
      _this.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      _this.deliveryService = deliveryService;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );
      _this.baseAddresses = _this.addresses;

      _this.addresses.find((address, index) => {
        if (address.addressId == _this.cart.shippingAddress.addressId) {
          _this.shippingAddress = index;
        }
      });
      _this.loading = false;
      _this.getProvincesList();
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        setTimeout(function() {
          _this.$router.push({ name: "Home" });
        }, 200);
      }
    },
    getProvincesList() {
      let array = [];
      this.addresses.map(a => {
        if (a.province.length) {
          array.push({ value: a.province, text: a.province });
        }
      });
      let unique = uniqBy(array, "value");
      this.provinces = orderBy(unique, p => p.value);
      this.provinces.unshift({ value: null, text: "Tutte le province" });
    },

    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("service");
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.showAddAddress = !this.showAddAddress;
    }
  },
  watch: {
    selectedProvince(val) {
      // eslint-disable-next-line no-debugger
      // debugger;
      if (!val) {
        this.addresses = this.baseAddresses;
      } else {
        this.filterAddressesByProvince(val.value);
      }
    }
  },
  created() {
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    this.fetchCategory();
  }
};
</script>
