<template>
  <!-- this type of banner is a vertical card -->
  <v-card
    rounded="md"
    :href="link"
    outlined
    @click.prevent="handleLink"
    :style="getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')"
    class="proposal-card d-flex"
    v-intersect.once="handleView"
    :id="`proposal-card-${proposal.id}`"
    :class="
      $ebsn.meta(
        proposal,
        'metaData.category_proposal_type.RESPONSIVE_BREAKPOINTS',
        ' card-no-responsive '
      )
    "
  >
    <div class="image-container" :style="getStyle('BG_IMAGE_COLOR')">
      <v-img
        class="image-card"
        eager
        :src="imgSrc"
        :alt="proposal.imgAlt"
        :title="proposal.imgDescription"
        :contain="
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.IMAGE_CONTAIN',
            false
          )
        "
      />
    </div>
    <div class="card-content">
      <v-card-title class="justify-center text-center" v-if="proposal.descr">{{
        proposal.descr
      }}</v-card-title>
      <v-card-text v-if="proposal.content" v-html="proposal.content">
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions
        class="justify-end"
        v-if="link && $ebsn.meta(proposal, 'category_proposal_type.BTN_TEXT')"
        :class="
          $ebsn.meta(
            proposal,
            'category_proposal_type.BTN_POSITION',
            ' justify-end '
          )
        "
      >
        <v-btn
          :href="link"
          @click.prevent="handleLink"
          depressed
          large
          :color="
            $ebsn.meta(
              proposal,
              'metaData.category_proposal_type.BTN_COLOR',
              'primary'
            )
          "
          :outlined="
            $ebsn.meta(proposal, 'category_proposal_type.BTN_OUTLINED', false)
          "
        >
          <!-- 
            COLOR TAKES OPPOSITE COLOR FROM BACKGROUND AND TEXT COLOR
            :style="
            $ebsn.meta(proposal, 'category_proposal_type.TEXT_COLOR', false) &&
            $ebsn.meta(
              proposal,
              'category_proposal_type.BACKGROUND_COLOR',
              false
            )
              ? getStyle('TEXT_COLOR', 'BACKGROUND_COLOR')
              : ''
          " -->
          {{ $ebsn.meta(proposal, "category_proposal_type.BTN_TEXT") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style lang="scss">
.proposal-card {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  .image-card {
    height: initial;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 100%;
    }
  }
  .v-card__text {
    text-align: center;
  }
  .btn-block {
    .v-btn {
      // same style as v-btn-block
      display: flex;
      flex: 1 0 auto;
      min-width: 100% !important;
      max-width: auto;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalCard",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  },
  methods: {
    getStyle(backgroundPath, colorPath) {
      let style = {};

      if (backgroundPath) {
        let backgroundColor = get(
          this.proposal,
          `metaData.category_proposal_type.${backgroundPath}`
        );
        if (backgroundColor) {
          style.backgroundColor = backgroundColor + " !important";
        }
      }
      if (colorPath) {
        let textColor = get(
          this.proposal,
          `metaData.category_proposal_type.${colorPath}`
        );
        if (textColor) {
          style.color = textColor + " !important";
        }
      }

      return style;
    }
  }
};
</script>
