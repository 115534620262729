var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"proposal-card d-flex",class:_vm.$ebsn.meta(
      _vm.proposal,
      'metaData.category_proposal_type.RESPONSIVE_BREAKPOINTS',
      ' card-no-responsive '
    ),style:(_vm.getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')),attrs:{"rounded":"md","href":_vm.link,"outlined":"","id":`proposal-card-${_vm.proposal.id}`},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('div',{staticClass:"image-container",style:(_vm.getStyle('BG_IMAGE_COLOR'))},[_c('v-img',{staticClass:"image-card",attrs:{"eager":"","src":_vm.imgSrc,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription,"contain":_vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.IMAGE_CONTAIN',
          false
        )}})],1),_c('div',{staticClass:"card-content"},[(_vm.proposal.descr)?_c('v-card-title',{staticClass:"justify-center text-center"},[_vm._v(_vm._s(_vm.proposal.descr))]):_vm._e(),(_vm.proposal.content)?_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.proposal.content)}}):_vm._e(),_c('v-spacer'),(_vm.link && _vm.$ebsn.meta(_vm.proposal, 'category_proposal_type.BTN_TEXT'))?_c('v-card-actions',{staticClass:"justify-end",class:_vm.$ebsn.meta(
          _vm.proposal,
          'category_proposal_type.BTN_POSITION',
          ' justify-end '
        )},[_c('v-btn',{attrs:{"href":_vm.link,"depressed":"","large":"","color":_vm.$ebsn.meta(
            _vm.proposal,
            'metaData.category_proposal_type.BTN_COLOR',
            'primary'
          ),"outlined":_vm.$ebsn.meta(_vm.proposal, 'category_proposal_type.BTN_OUTLINED', false)},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$ebsn.meta(_vm.proposal, "category_proposal_type.BTN_TEXT"))+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }