<template>
  <v-img
    id="static - image"
    :src="$ebsn.meta(config, categoryblocktype_StaticImage.IMAGE)"
  />
</template>
<script>
export default {
  name: "StaticImage",
  props: {
    config: { type: Object, required: true }
  }
};
</script>
