<template functional>
  <div class="promo-box d-flex" :class="props.warehousePromo.view.cssClass">
    <span v-html="props.warehousePromo.view.header" class="promo-header"></span>
    <span v-html="props.warehousePromo.view.body" class="promo-body"></span>
    <span v-html="props.warehousePromo.view.footer" class="promo-footer"></span>
  </div>
</template>
<style lang="scss">
.promo-box {
  position: relative;
  background-color: $primary;
  color: #ffffff;
  font-size: 1.3rem;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0px 10px;
  font-weight: bold;
  line-height: 1.4;
  width: fit-content;
  flex-grow: initial;
  flex-basis: initial;
  strong {
    font-size: 1.5rem;
    font-weight: bold;
  }
  em {
    font-size: 1.3rem;
  }
  &.new-product {
    color: #fff;
    background-color: var(--v-accent-base);
    .promo-body {
      padding-top: 1px;
    }
  }
  &.promo_risparmio {
    background: white;
    border: 1px solid $primary;
    color: $primary;
    .promo-body {
      padding-top: 1px;
    }
  }
  &.promo_piubassi {
    border-radius: 0;
    border-top-left-radius: $border-radius-root * 2;
    padding: 4px 6px;
    .promo-body {
      border: 1px solid #fff;
      border-radius: 0;
      border-top-left-radius: $border-radius-root * 2;
      line-height: 1.1;
      padding: 2px 6px 0px 6px;
    }
  }
  &.promo_mxn {
    .promo-body {
      font-size: 1.5rem;
    }
  }
  &.promo_sotto {
    .promo-footer {
      display: block;
      position: absolute;
      bottom: -15px;
      font-size: 1.5rem;
      color: $primary;
    }
  }
  &.sconti_per_te {
    background-color: rgb(224, 24, 131);
  }
  &.omaggio_oro {
    background-color: #c4a26e;
  }
  &.sconti_per_te,
  &.omaggio_oro {
    .promo-body {
      img {
        width: 16px;
        margin-bottom: -2px;
      }
    }
  }
}
</style>
<script>
export default {
  name: "ProductPromo",
  props: {
    warehousePromo: { type: Object, required: true }
  }
};
</script>
