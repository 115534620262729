const UPDATE_PRICE_DISPLAY_DISCLAIMER = "updatePriceDisplayDisclaimer";

const state = {
  priceDisplayDisclaimer: true
};
const getters = {};

const actions = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]({ commit }, value) {
    commit(UPDATE_PRICE_DISPLAY_DISCLAIMER, value);
  }
};
const mutations = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]: (state, value) => {
    state.priceDisplayDisclaimer = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
