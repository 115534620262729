var render = function render(_c,_vm){return _c('div',{staticClass:"title-row d-flex flex-column flex-md-row justify-md-space-between align-start"},[_c('ebsn-meta',{attrs:{"target":_vm.props.config,"path":`categoryblocktype_${_vm.props.categoryBlockName}.TITLE`,"tag":"h2","cssClass":"slider-title font-weight-bold mr-3"}}),(
      _vm.props.showMore &&
        !_vm.parent.$ebsn.meta(
          _vm.props.config,
          'categoryblocktype_' + _vm.props.categoryBlockName + '.HIDE_LINK'
        )
    )?_c('v-btn',{staticClass:"align-self-end align-self-md-center mx-3",attrs:{"color":"primary","depressed":"","to":_vm.$options.calcLinkToGo(
        _vm.props.config,
        _vm.props.categoryBlockName,
        _vm.parent.$ebsn.meta
      )}},[_vm._v(" "+_vm._s(_vm.parent.$ebsn.meta( _vm.props.config, "categoryblocktype_" + _vm.props.categoryBlockName + ".BUTTON_TEXT", _vm.parent.$t("categoryBlock.productListSlider.showAll") ))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }